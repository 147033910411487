<section class="navbar-custom2">
  <div class="container">
    <div id="carousel-navbar">
      <div (click)="scroll(section.slug)" class="item-navbar-custom2 item-directorio-administracion"
        *ngFor="let section of sections; let i = index">
        <h6 class="title-navbar-custom">{{ section.titulo }}</h6>
      </div>
    </div>
  </div>
</section>
<div class="bg-white bg-white-custom">
  <section class="section-banner-custom-new" id="section0" style="background-image: url(assets/img/img-test2.jpg);">
    <div class="overlay-banner-custom-new"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">Control Interno</h2>
          <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
            data-aos-delay="400"></span>
          <!-- <div class="box-text-banner-custon-new" data-aos="fade-right" data-aos-duration="700">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
          </div> -->
        </div>
      </div>
    </div>
  </section>
  <div id="animationWindow"></div>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs breadcrumbs-sections">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>Control Interno</span>
        </div>
      </div>
    </div>
  </section>
  <div *ngFor="let section of sections; let i = index" class="sections-custom-new">
    <section class="section-custom-new" id="{{section.slug}}"
      [ngClass]="{'section-comunidad': section.titulo == 'Comunidad de Buenas Prácticas'}">
      <div class="container">
        <div class="row">
          <div class=""
            [ngClass]="{'col-lg-3': section.video || section.imagen, 'col-lg-4': !section.video && !section.imagen }">
            <div class="box-title-custom-new">
              <h3 class="title-custom-new" data-aos="fade-up" data-aos-duration="700">{{ section.titulo }}</h3>
              <span class="line-title-custom-new" data-aos="fade-right" data-aos-duration="700"
                data-aos-delay="400"></span>
            </div>
          </div>
          <div class=""
            [ngClass]="{'col-lg-6': section.video || section.imagen, 'col-lg-8': !section.video && !section.imagen}">
            <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
              <!-- <div class="text-destaqued-custom-new">
                <h5>{{ section.subtitulo }}</h5>
              </div> -->
              <div class="text-custom-new">
                <p [innerHTML]="textMin(section.descripcion_corta)"></p>
              </div>
              <span *ngIf="section.titulo == 'Canal de denuncias'">
                <a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank"
                  class="btn-custom-new" *ngIf="section.enlace">
                  <span>VER MÁS</span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </span>
              <span *ngIf="section.titulo != 'Canal de denuncias'">
                <a [routerLink]="[section.enlace]" class="btn-custom-new" *ngIf="section.enlace">
                  <span>VER MÁS</span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                      </g>
                    </g>
                  </svg>
                </a>
                <span
                  (click)="fullTextModal(section.titulo , section.texto, section.banners, section.slug,section.documentos)"
                  class="btn-custom-new" *ngIf="!section.enlace">
                  <span>VER MÁS</span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                      </g>
                    </g>
                  </svg>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-img-section-custom-new" *ngIf="section.video || section.imagen">
        <div class="diagonal-cut"></div>
        <video autoplay muted loop [muted]="true" *ngIf="section.video">
          <source src="{{ section.video }}">
        </video>
        <img *ngIf="section.imagen" src="{{ section.imagen }}" alt="">
        <!-- <img *ngIf="section.imagen" src="{{ section.imagen }}" alt="" [ngClass]="{'custom-position1': section.titulo == 'Gestión de Riesgo' }"> -->
      </div>
    </section>

    <section class="section-image-divider" *ngIf="section.imagen_separacion"
      [ngStyle]="{ 'background-image': 'url(' + section.imagen_separacion + ')'}">
    </section>
  </div>
  <div>
    <div class="tabs-search">
      <button data-tab="#news-general" class="item-tab-search active">Noticias</button>
      <button data-tab="#galeries-general" class="item-tab-search" *ngIf="galeria1">Galería</button>
      <button data-tab="#videos-general" class="item-tab-search" *ngIf="galeriaVideos1?.length > 0">Videos</button>
    </div>
    <div class="tabs-content-search">
      <div class="item-tab-content-search" id="news-general">
        <section class="section-custom5">
          <div class="container">
            <!-- <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">NOTICIAS</h3>
            <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span> -->
            <div class="swiper-container" #carouselNoticias [swiper]="carouselCharlas.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let new1 of news1; let i = index">
                  <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ new1.img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="new1.title"></h4>
                      <div class="card-text">
                        <p [innerHTML]="new1.text"></p>
                      </div>
                      <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'hacer-las-cosas-bien' }"
                  class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="galeries-general" *ngIf="galeria1" style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeria1; let i = index">
                  <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                    class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                    data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.feature_img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-image-section/control-interno' ]" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="videos-general" *ngIf="galeriaVideos1?.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaVideos1; let i = index">
                  <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                    (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.img }}" alt="">
                      <div class="diagonal-cut"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                        class="btn-play-desing1">
                    </div>
                    <div class="card-content">
                      <h4 class="card-title">{{ item.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-videos-section/control-interno' ]" [queryParams]="1" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <section class="section-custom-new3">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">DOCUMENTOS</h3>
          <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
          <div class="content-documents-custom-new3">
            <a href="{{ documento.link }}" class="item-document-custom-new3"
              *ngFor="let documento of documentos; let i = index" target="_blank" data-aos="fade-up"
              data-aos-duration="700" data-aos-delay="0">
              <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
<div class="modal-custom3-text">
  <div class="overlay-modal"></div>
  <div class="box-custom3-text">
    <button class="close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="title-custom3-text">Levantamiento del proceso</h3>
    <div class="box-text-custome-text">
      <p>Entrevistas y reuniones de validación con los dueños y responsables del proceso con el objetivo de elaborar el
        informe de levantamiento de la situación actual y el diagrama de flujos de las actividades realizadas.</p>
    </div>
  </div>
</div>
<div class="modal-img-full modal-single-content" [ngClass]="modalTextShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullTextModal()"></div>
  <div class="box-modal-text-full">
    <div class="modal-header-single-content">
      <button class="btn-back-single-content" (click)="closeFullTextModal()">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"
          fill="#ff7000">
          <g>
            <g>
              <path
                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
            </g>
          </g>
        </svg>
      </button>
      <h3 class="title-header-modal">Control Interno / <span [innerHTML]="modalTitulo"></span></h3>
      <!-- <button class="close" (click)="closeFullTextModal()">
        <img src="assets/img/btn-close.svg" alt="">
      </button> -->
    </div>
    <div class="modal-text">
      <div class="modal-content-text">
        <div class="modal-auditoria-interna" *ngIf="modalTitulo == 'Auditoría interna'">
          <div class="banner-auditoria-interna" *ngIf="modalBanners.length > 1">
            <div class="swiper-container" [swiper]="carouselBanner.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let modalBanner of modalBanners; let i = index">
                  <img src="{{ modalBanner }}" alt="">
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
          </div>
          <div class="banner-auditoria-interna" *ngIf="modalBanners.length == 1">
            <img src="{{ modalBanners[0] }}" alt="">
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-11 mx-auto">
                <div class="box-text-auditoria-interna">
                  <p>La Subgerencia de Auditoría Interna, responsable por las actividades de auditoría interna en
                    Abastible, es una función de carácter independiente cuyas responsabilidades son establecidas
                    aprobadas por el Directorio.</p>
                  <p><b>Nuestro Objetivo</b>: proteger el patrimonio, agregar valor y lograr la mejora continua de las
                    operaciones de la Compañía a través del aseguramiento y la consultoría basadas en la exposición al
                    riesgo de Abastible. Es de esta forma como la función de AI apoya el desarrollo del Sistema de
                    Control Interno, el alcance de objetivos estratégicos y la sostenibilidad de la Compañía.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="box-button-auditoria-interna">
            <a [routerLink]="['/group/comunidad-buenas-practicas']" class="btn-auditoria-interna">Ingresa al grupo
              Comunidad Buenas Prácticas</a>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-11 mx-auto">
                <h3 class="title-auditoria-interna">
                  <span>¿Quiénes</span><br>
                  <b>somos?</b>
                </h3>
                <div class="row justify-content-center row-items-integrantes-auditoria-interna">
                  <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1"
                    data-aos="fade-up" data-aos-duration="700">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="assets/img/veronica-martinez.jpg" alt="" class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Verónica Martínez</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Subgerente Auditoría interna</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="assets/img/yasna-parra.png" alt="" class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Yasna Parra</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Especialista en Sucursales</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="assets/img/xiomara-alvarez.jpg" alt="" class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Xiomara Álvarez</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Auditor Interno Senior</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="assets/img/john-sierra.png" alt="" class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">John Sierra</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Auditor Interno Senior</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="assets/img/giselle-cerda.png" alt="" class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Giselle Cerda</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Auditor Interno Senior</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="assets/img/nicole-olivari.jpg" alt="" class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Nicole Olivari</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Auditor Interno Senior</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="assets/img/danae-tapia.jpg" alt="" class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Danae Tapia</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Auditor Interno Senior</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="assets/img/ana-ruiz.jpg" alt="" class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Ana Ruiz</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Especialista en Auditoria TI</p>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 class="title-auditoria-interna title-auditoria-interna-color2">
                  <span>Tres Líneas</span><br>
                  <b>de defensa</b>
                </h3>
                <div class="box-text-auditoria-interna">
                  <p>Importante componente del Sistema de Control Interno. Cada línea de defensa tiene funciones y
                    responsabilidades específicas y trabajan en estrecha colaboración para identificar, evaluar y
                    mitigar los riesgos respecto de los objetivos estratégicos y de negocio. </p>
                </div>
                <div class="items-lineas-defensa">
                  <div class="item-lineas-defensa">
                    <h3 class="title-item-lineas-defensa">1ª Línea de defensa</h3>
                    <div class="box-text-item-lineas-defensa">
                      <p>Corresponde a la Administración, la que tiene la responsabilidad sobre los resultados
                        operacionales del negocio, cumplimiento y gestión de los riesgos que afectan a su negocio. Por
                        ello, la Administración es responsable de desarrollar e implementar controles para mitigar los
                        riesgos, a su vez asume las consecuencias de las pérdidas económicas o daños reputacionales.
                      </p>
                    </div>
                  </div>
                  <div class="item-lineas-defensa">
                    <h3 class="title-item-lineas-defensa">2ª Línea de defensa</h3>
                    <div class="box-text-item-lineas-defensa">
                      <p>Conformada por Gestión de Procesos, Gestión de Riesgos y Legal, son quienes ayudan al negocio a
                        formular políticas y estructuras organizacionales y estrategias operacionales que les permitan
                        administrar los riesgos. Identifica, en conjunto con las áreas funcionales, las necesidades,
                        regulaciones y políticas aplicables las informa al negocio. Además, el área de Legal es la
                        responsable de transmitir las diferentes leyes, regulaciones y circulares emitidas por las
                        autoridades competentes y monitorea su cumplimiento. </p>
                    </div>
                  </div>
                  <div class="item-lineas-defensa item-lineas-defensa-with-image">
                    <img src="assets/img/monito-azul.png" class="img-item-lineas-defensa" alt="" />
                    <h3 class="title-item-lineas-defensa">3ª Línea de defensa</h3>
                    <div class="box-text-item-lineas-defensa">
                      <p>Auditoría Interna es la tercera línea de defensa. Su misión es proporcionar una evaluación
                        independiente del diseño y la efectividad de los controles internos sobre los riesgos para el
                        desempeño del negocio, incluyendo la actividad de gestión de riesgos realizada por la 1ª y 2ª
                        línea de defensa. Auditoría Interna proporcionará recomendaciones específicas para mejorar el
                        gobierno corporativo y marco de control interno. Auditoría Interna realiza auditorías integradas
                        de riesgo específico para abordar riesgos relevantes, que incluyen, entre otros, riesgos
                        Estratégicos, Financieros, Operacionales, de Tecnología, de Gobierno y de Cumplimiento. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-actuamos">
            <div class="container">
              <div class="col-lg-11 mx-auto">
                <h3 class="title-auditoria-interna title-auditoria-interna-oneline title-auditoria-interna-color2">
                  <span>Así</span><br>
                  <b>actuamos</b>
                </h3>
              </div>
              <div class="box-video-actuamos">
                <img src="assets/img/preview-video.jpg" class="video-preview-desafio6" alt="" />
                <button class="btn-play-video">
                  <img src="assets/img/icon-play.png" alt="" />
                </button>
                <video poster="assets/img/preview-video.jpg" controls>
                  <source
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/03/Presentacion-personajes-v5-1.mp4">
                </video>
              </div>
            </div>
          </div>
          <div class="container section-labores">
            <div class="row">
              <div class="col-lg-11 mx-auto">
                <h3 class="title-auditoria-interna title-auditoria-interna-color2">
                  <span>Las labores</span><br>
                  <b>que realizamos</b>
                </h3>
                <div class="box-tabs-auditoria">
                  <img src="assets/img/monito-azul2.png" alt="" class="img-tabs-auditoria">
                  <div class="box-tabs-auditoria-content">
                    <div class="items-tabs-auditoria-header">
                      <div class="item-tabs-auditoria active" data-target="#item-tab-procesos">
                        <h4 class="title-item-tabs-auditoria">Auditoría<span>de procesos</span></h4>
                      </div>
                      <div class="item-tabs-auditoria" data-target="#item-tab-sucursales">
                        <h4 class="title-item-tabs-auditoria">Auditorías<span>de sucursales</span></h4>
                      </div>
                      <div class="item-tabs-auditoria" data-target="#item-tab-denuncias">
                        <h4 class="title-item-tabs-auditoria">Investigación<span>de denuncias</span></h4>
                      </div>
                      <div class="item-tabs-auditoria" data-target="#item-tab-especial-consultoria">
                        <h4 class="title-item-tabs-auditoria">Auditorías<span> especiales y consultoría</span></h4>
                      </div>
                    </div>
                    <div class="items-tabs-auditoria-body">
                      <div class="item-tabs-auditoria" id="item-tab-procesos" style="display: block;">
                        <p>Auditorías a los procesos de negocio bajo metodología basada en riesgos, analizando las
                          debilidades y fortalezas, identificando mejoras oportunas realizando trabajos que aporten
                          valor a la compañía. Seguimiento a los planes de acción y establecer un rol de ayuda para
                          velar, junto con la Administración, por establecer un sólido sistema de control interno.</p>
                      </div>
                      <div class="item-tabs-auditoria" id="item-tab-sucursales">
                        <p>Auditorías de sucursales, aplicando las normativas internas y externas para asegurar un grado
                          de control óptimo.</p>
                      </div>
                      <div class="item-tabs-auditoria" id="item-tab-denuncias">
                        <p>Proceso mediante el cual se analiza la denuncia, ponderando todos los antecedentes que le
                          sean entregados o que pueda recabar la subgerencia de auditoría interna, emitiéndose al EPDF
                          el correspondiente Informe con las conclusiones obtenidas a partir de ésta. La investigación
                          deberá constar por escrito, ser llevada en estricta reserva, garantizando que ambas partes
                          sean oídas y puedan fundamentar sus dichos, con estricto apego a los principios de
                          imparcialidad y bilateralidad, y en general, con respeto a los derechos de los colaboradores o
                          terceros involucrados en la misma.</p>
                      </div>
                      <div class="item-tabs-auditoria" id="item-tab-especial-consultoria">
                        <p>Revisiones especiales, de acuerdo a las necesidades del negocio (por iniciativa del Gerente
                          de Control Interno, Subgerente de Auditoría o cliente interno)</p>
                        <p>Consultorías para desarrollos e implementación de nuevos procesos de negocio, velando que los
                          nuevos procesos de negocio cuenten con los controles previamente recomendados, cumpliendo con
                          los procedimientos y normas.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Documentos -->
          <div *ngIf="modalDocuments?.length > 0" class="pt-5 pb-3">
            <div class="tabs-search">
              <button data-tab="#documentos-compliance" class="item-tab-search active">Documentos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="documentos-compliance">
                <div class="col-lg-8 mx-auto mb-5">
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" mt-5">
            <div class="tabs-search">
              <button data-tab="#news-auditoria-interna" class="item-tab-search active"
                *ngIf="newsAuditoriaInterna?.length > 0">Noticias</button>
              <button data-tab="#galeries-auditoria-interna" class="item-tab-search "
                *ngIf="galeriaVideosAuditoriaInterna?.length > 0">Galería</button>
              <button data-tab="#videos-auditoria-interna" class="item-tab-search "
                *ngIf="galeriasAuditoriaInterna?.length > 0">Videos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="news-auditoria-interna" *ngIf="newsAuditoriaInterna?.length > 0">
                <section class="section-custom5">
                  <div class="container" id="charlas">
                    <div class="swiper-container" [swiper]="carouselCharlas4.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide"
                          *ngFor="let newAuditoriaInterna of newsAuditoriaInterna; let i = index">
                          <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ newAuditoriaInterna.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="newAuditoriaInterna.title"></h4>
                              <div class="card-text">
                                <p [innerHTML]="newAuditoriaInterna.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + newAuditoriaInterna.slug ]" class="card-link">Leer más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                        <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'auditoria-interna' }"
                          class="btn-custom-new">
                          <span>VER MÁS</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="galeries-auditoria-interna" style="display: none;"
                *ngIf="galeriaVideosAuditoriaInterna?.length > 0">
                <section class="section-custom5">
                  <div class="container mb-5" id="charlas">
                    <div class="swiper-container" [swiper]="carouselCharlas5.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriaVideosAuditoriaInterna; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700"
                            *ngIf="item.youtube == ''">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideoIframe($event, item.video_url)" *ngIf="item.youtube != ''"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/auditoria-interna' ]" [queryParams]="1"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="videos-auditoria-interna" style="display: none;"
                *ngIf="galeriasAuditoriaInterna?.length > 0">
                <section class="section-custom5">
                  <div class="container" id="charlas">
                    <div class="swiper-container" [swiper]="carouselCharlas6.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriasAuditoriaInterna; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="100">
                      <a [routerLink]="['/galeria-image-section/auditoria-interna' ]" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="modalTitulo == 'Seguridad de la Información'">

          <div class="banner-seleccion-talento" *ngIf="modalBanners.length > 1">
            <div class="swiper-container" [swiper]="carouselBanner.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let modalBanner of modalBanners; let i = index">
                  <img src="{{ modalBanner }}" alt="">
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
          </div>
          <div class="banner-seleccion-talento" *ngIf="modalBanners.length == 1">
            <img src="{{ modalBanners[0] }}" alt="">
          </div>
          <div class="section-design2-equipo section-design2-equipo-banner-title">
            <div class="container">
              <div class="row align-items-center row">
                <div class="col-lg-5">
                  <h3 class="title-section-design2 title-section-design2-2"><span>Objetivos del
                      área</span><br>Seguridad<br><span>de la
                      Información</span></h3>
                </div>
                <div class="col-lg-7">
                  <div class="box-text-section-design2">
                    <p>Es un área comprometida en la contribución del cumplimiento de los objetivos de la compañía,
                      mediante la protección de
                      los activos de información de Abastible, contra las amenazas que pongan en riesgo su
                      confidencialidad, disponibilidad e
                      integridad.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-design2">
            <img src="assets/img/bg-section-design2-2.png" alt="" class="bg-top-section-design2">
            <div class="container">
              <h3 class="title-section-design2">Equipo</h3>
            </div>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1"
                      data-aos="fade-up" data-aos-duration="700">
                      <div class="overlay-equipo-custom1"></div>
                      <img src="assets/img/miguel.png" alt="" class="img-box-equipo-custom1">
                      <div class="box-content-equipo-custom1">
                        <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Miguel Saldias</h3>
                        <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
                          data-aos-delay="100"></span>
                        <div class="box-text-equipo-custom1">
                          <p>Oficial de Seguridad de la Información (CISO)</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1"
                      data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <div class="overlay-equipo-custom1"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/02/Imagen2-1.png" alt=""
                        class="img-box-equipo-custom1">
                      <div class="box-content-equipo-custom1">
                        <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Jordan Rivero</h3>
                        <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
                          data-aos-delay="100"></span>
                        <div class="box-text-equipo-custom1">
                          <p>Analista Seguridad de la Información</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1"
                      data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
                      <div class="overlay-equipo-custom1"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/02/Juan-Aedo-SI.jpg" alt=""
                        class="img-box-equipo-custom1">
                      <div class="box-content-equipo-custom1">
                        <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Juan Aedo
                        </h3>
                        <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
                          data-aos-delay="100"></span>
                        <div class="box-text-equipo-custom1">
                          <p>Ingeniero de Control de Accesos</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1"
                      data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
                      <div class="overlay-equipo-custom1"></div>
                      <img src="assets/img/dimitri.png" alt="" class="img-box-equipo-custom1">
                      <div class="box-content-equipo-custom1">
                        <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Dimitri Quioza</h3>
                        <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
                          data-aos-delay="100"></span>
                        <div class="box-text-equipo-custom1">
                          <p>Ingeniero Especialista de Ciberseguridad</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-design2 section-design2-conceptos">
            <img src="assets/img/bg-section-design2-1.png" alt="" class="bg-top-section-design2">
            <div class="container">
              <h3 class="title-section-design2">Conceptos</h3>
              <div class="row">
                <div class="col-lg-3">
                  <div class="card-concepto item-open-modal" data-title="Seguridad de la Información"
                    data-text="Proceso continuo destinado a proteger los activos de información de la organización con un conjunto de medidas preventivas y reactivas, contra las amenazas y vulnerabilidades que pongan en riesgo su confidencialidad, disponibilidad e integridad.">
                    <img src="assets/img/icon-seguridad1.png" alt="" class="card-concepto-img">
                    <h3 class="card-concepto-title">Seguridad de la
                      Información</h3>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card-concepto item-open-modal" data-title="Activo de Información"
                    data-text='Son todos aquellos elementos relevantes en la producción, emisión, almacenamiento, comunicación, visualización y recuperación de la información. Se destacan tres niveles<ul class="pt-2"><li>La información propiamente tal, en sus múltiples formatos (papel, digital, texto, audio, imagen, etc)</li><li>Los equipos, sistemas e infraestructura que soportan la información</li><li>Y las personas que utilizan la información</li></ul>'>
                    <img src="assets/img/icon-seguridad2.png" alt="" class="card-concepto-img">
                    <h3 class="card-concepto-title">Activo de
                      Información</h3>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card-concepto item-open-modal" data-title="Confidencialidad"
                    data-text="Es el nivel de secreto o resguardo que debe tener la información en especifico, por ejemplo, los datos personales de los clientes o tu propia contraseña.">
                    <img src="assets/img/icon-seguridad3.png" alt="" class="card-concepto-img">
                    <h3 class="card-concepto-title">Confidencialidad</h3>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card-concepto item-open-modal" data-title="Disponibilidad"
                    data-text="Hace referencia al tiempo máximo de no poder acceder a la información y cuyo objetivo es mantener equipos y software siempre disponibles, asegurando la continuidad de los negocios y operaciones.">
                    <img src="assets/img/icon-seguridad4.png" alt="" class="card-concepto-img">
                    <h3 class="card-concepto-title">Disponibilidad</h3>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card-concepto item-open-modal" data-title="Integridad"
                    data-text="Corresponde a la exactitud y completitud de la información y cuyo objetivo es garantizar la autenticación de esta misma, validando su origen y su creación.">
                    <img src="assets/img/icon-seguridad5.png" alt="" class="card-concepto-img">
                    <h3 class="card-concepto-title">Integridad</h3>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card-concepto item-open-modal" data-title="Ingeniería Social"
                    data-text="Es una técnica de estafa realizada por los ciberdelincuentes, que consiste en cualquier acto que busca influir en una persona para que tome acciones que pueden o no ser en su beneficio. Estas técnicas de estafa buscan obtener información confidencial de la persona, de manera que esta entregue la información con total confianza.">
                    <img src="assets/img/icon-seguridad6.png" alt="" class="card-concepto-img">
                    <h3 class="card-concepto-title">Ingeniería Social</h3>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card-concepto item-open-modal" data-title="Phishing"
                    data-text="Es una de las herramientas usadas por la ingeniería social para engañar a las personas, persuadiéndolas a que compartan información confidencial, como contraseñas y números de tarjetas de crédito, o instalar un programa para infectar la red y obtener información, a través de correo electrónico.">
                    <img src="assets/img/icon-seguridad7.png" alt="" class="card-concepto-img">
                    <h3 class="card-concepto-title">Phishing</h3>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card-concepto item-open-modal" data-title="Malware"
                    data-text="Corresponde a cualquier software malicioso; antes conocido como virus, que tiene una familia de códigos maliciosos como: troyanos, spyware, ransomware, u otros, que tratan de afectar un computador, smartphone u otro dispositivo.">
                    <img src="assets/img/icon-seguridad8.png" alt="" class="card-concepto-img">
                    <h3 class="card-concepto-title">Malware</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section-design2 section-design2-ciberseguridad">
            <img src="assets/img/bg-section-design2-2.png" alt="" class="bg-top-section-design2">
            <div class="container">
              <h3 class="title-section-design2">Marco de Ciberseguridad NIST</h3>
              <div class="box-section-design2-ciberseguridad">
                <div class="section-design2-ciberseguridad-left">
                  <a href="#"
                    class="section-design2-ciberseguridad-left-link section-design2-ciberseguridad-left-link1 item-open-modal"
                    data-title="Identificar"
                    data-text="Ayuda a desarrollar un entendimiento organizacional para administrar el riesgo de ciberseguridad de los sistemas, las personas, los activos, los datos y las capacidades. La comprensión del contexto empresarial, los recursos que respaldan las funciones críticas y los riesgos relacionados con la ciberseguridad permiten que una organización se centre y priorice sus esfuerzos, de acuerdo con su estrategia de administración de riesgos y sus necesidades comerciales.">Identificar</a>
                  <a href="#"
                    class="section-design2-ciberseguridad-left-link section-design2-ciberseguridad-left-link2 item-open-modal"
                    data-title="Proteger"
                    data-text="Describe las medidas de seguridad adecuadas para garantizar la entrega de servicios de las infraestructuras críticas. Esta función contempla la capacidad de limitar o contener el impacto de un potencial evento de ciberseguridad.">Proteger</a>
                  <a href="#"
                    class="section-design2-ciberseguridad-left-link section-design2-ciberseguridad-left-link3 item-open-modal"
                    data-title="Detectar"
                    data-text="Define las actividades necesarias para identificar la ocurrencia de un evento de ciberseguridad., permitiendo el descubrimiento oportuno de los mismos.">Detectar</a>
                  <a href="#"
                    class="section-design2-ciberseguridad-left-link section-design2-ciberseguridad-left-link4 item-open-modal"
                    data-title="Responder"
                    data-text="Incluye actividades necesarias para tomar medidas con respecto a un incidente de ciberseguridad detectado, desarrollando la capacidad de contener el impacto de un potencial incidente.">Responder</a>
                  <a href="#"
                    class="section-design2-ciberseguridad-left-link section-design2-ciberseguridad-left-link5 item-open-modal"
                    data-title="Recuperar"
                    data-text="Identifica las actividades necesarias para mantener los planes de resiliencia y para restaurar cualquier capacidad o servicio que se haya deteriorado debido a un incidente de ciberseguridad. Esta función es compatible con la recuperación oportuna de las operaciones normales para reducir el impacto de un incidente de ciberseguridad.">Recuperar</a>
                  <img src="assets/img/img-ciberseguridad.png" alt="" class="section-design2-ciberseguridad-left-img">
                </div>
                <div class="section-design2-ciberseguridad-right">
                  <img src="assets/img/img-ciberseguridad2.png" alt="" class="section-design2-ciberseguridad-right-img">
                </div>
              </div>
            </div>
          </div>

          <div class="section-design2 section-design2-confidencial">
            <img src="assets/img/bg-section-design2-1.png" alt="" class="bg-top-section-design2">
            <div class="container">
              <div class="row">
                <div class="col-lg-10 mx-auto">
                  <div class="row">
                    <div class="col-lg-5">
                      <img src="assets/img/img-confidencial.png" class="img-fluid" alt="">
                    </div>
                    <div class="col-lg-7">
                      <div class="section-design2-confidencial-text">
                        <p>Utiliza el sello para etiquetar tus archivos
                          de carácter Confidencial y Privado</p>
                      </div>
                      <div class="section-design2-confidencial-img">
                        <a href="assets/img/sello.png" download=""><img src="assets/img/sello.png" alt=""
                            class="img-fluid"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section-design2">
            <img src="assets/img/bg-section-design2-2.png" alt="" class="bg-top-section-design2">
            <div class="tabs-search">
              <button data-tab="#reglamentos-seguridad-informacion" class="item-tab-search active">Documentos</button>
              <button data-tab="#documentos-seguridad-informacion" class="item-tab-search ">Archivos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="reglamentos-seguridad-informacion">
                <div class="col-lg-8 mx-auto my-5">
                  <!-- <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">REGLAMENTOS</h3>
                                        <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
                                            data-aos-delay="100"></span> -->
                  <div class="content-documents-custom-new3 pt-0">
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/10/Reglamento-Clasificación-de-la-Información-v2.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento Clasificación de la Información</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/10/Reglamento-de-Requisitos-de-Seguridad-en-la-Infraestructura-para-Proyectos-TI.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento de Requisitos de Seguridad en la Infraestructura
                        para Proyectos TI</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/10/Reglamento-de-Requisitos-de-Seguridad-para-Desarrollo-o-Adquisición-de-Aplicativos.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento de Requisitos de Seguridad para Desarrollo o
                        Adquisición de Aplicativos</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/10/Reglamento-Uso-Internet-v.2.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento Uso Internet</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/10/Politica-General-de-Seguridad-de-la-Información-16.05.2022.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Politica General de Seguridad de la Información</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/10/Procedimiento-de-Gestión-de-Vulnerabilidades-para-Servidores-v.2.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Procedimiento de Gestión de Vulnerabilidades para
                        Servidores</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <!-- <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/07/Politica-General-de-Seguridad-de-la-Informaci%C3%B3n-16.05.2022.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Politica Seguridad de la Información</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2019/04/Reglamento_Caracteristicas_de_Passwords.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento Caracteristicas de Passwords</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2019/04/Reglamento_Conexiones_Remotas.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento Conexiones Remotas</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2019/04/Reglamento_uso_internet.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento Uso Internet</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2019/04/Reglamento_uso_mail.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento Uso Mail</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                    <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2019/04/Clasificacion_de_Informacion.pdf"
                      class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="0">
                      <h3 class="title-document-custom-new3">Reglamento Clasificacion de Información</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a> -->
                  </div>
                </div>
              </div>
              <div class="item-tab-content-search" id="documentos-seguridad-informacion" style="display: none;">
                <div class="col-lg-8 mx-auto mb-5">
                  <!-- <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">DOCUMENTOS</h3>
                                        <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
                                            data-aos-delay="100"></span> -->
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section-design2">
            <img src="assets/img/bg-section-design2-1.png" alt="" class="bg-top-section-design2">
            <div class="tabs-search">
              <button data-tab="#news-seguridad-informacion" class="item-tab-search active">Noticias</button>
              <button data-tab="#galeries-seguridad-informacion" class="item-tab-search "
                *ngIf="galeriaSeguridad">Galería</button>
              <button data-tab="#videos-seguridad-informacion" class="item-tab-search ">Videos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="news-seguridad-informacion">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselCharlas2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide"
                          *ngFor="let newSeguridadInformacion of newsSeguridadInformacion; let i = index">
                          <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ newSeguridadInformacion.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="newSeguridadInformacion.title"></h4>
                              <div class="card-text">
                                <p [innerHTML]="newSeguridadInformacion.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + newSeguridadInformacion.slug ]" class="card-link">Leer
                                más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                        <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'seguridad-de-la-informacion' }"
                          class="btn-custom-new">
                          <span>VER MÁS</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="galeries-seguridad-informacion" *ngIf="galeriaSeguridad"
                style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselCharlas2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriaSeguridad; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="100">
                      <a [routerLink]="['/galeria-image-section/seguridad-de-la-informacion' ]" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="videos-seguridad-informacion" style="display: none;">
                <section class="section-custom5">
                  <div class="container" *ngIf="modalTitulo == 'Seguridad de la Información'">
                    <div class="swiper-container" [swiper]="carouselCharlas3.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriaVideosSeguridad; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700"
                            *ngIf="item.youtube == ''">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideoIframe($event, item.video_url)" *ngIf="item.youtube != ''"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/seguridad-de-la-informacion' ]" [queryParams]="1"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

        </div>
        <section class="page-duenos-procesos" *ngIf="modalTitulo == 'Dueños de procesos'">
          <section class="page-duenos-procesos-banner">
            <img src="assets/img/banner-duenps.jpg" alt="" class="page-duenos-procesos-banner-img">
          </section>
          <section class="page-duenos-procesos-section1">
            <div class="container">
              <div class="page-duenos-procesos-section1-content">
                <div class="page-duenos-procesos-section1-content-text">
                  <p [innerHTML]="infoProcesos.texto_seccion1"></p>
                </div>
              </div>
            </div>
          </section>
          <section class="page-duenos-procesos-section2">
            <div class="container">
              <div class="page-duenos-procesos-section2-content">
                <div class="swiper-container" #carouselNoticias [swiper]="carouselMembers.config">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide swiper-slide-equipo{{i}}"
                      *ngFor="let element of infoProcesos.duenos_de_procesos; let i = index">
                      <div class="box-equipo-custom1 box-equipo-custom2" data-aos="fade-up" data-aos-duration="700">
                        <div class="overlay-equipo-custom1"></div>
                        <img src="{{element.foto}}" alt="" class="img-box-equipo-custom1">
                        <div class="box-content-equipo-custom1">
                          <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">
                            {{element.nombre}}</h3>
                          <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
                            data-aos-delay="100"></span>
                          <div class="box-text-equipo-custom1">
                            <p [innerHTML]="element.cargo"></p>
                          </div>
                        </div>
                        <button class="box-equipo-custom1-btn-remove" (click)="removeMemberProcess(i)"
                          *ngIf="userLogged.role_process">
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-button-prev">
                    <span class="material-icons">
                      keyboard_arrow_left
                    </span>
                  </div>
                  <div class="swiper-button-next">
                    <span class="material-icons">
                      keyboard_arrow_right
                    </span>
                  </div>
                </div>
                <a [routerLink]="['/duenos-de-procesos' ]" class="page-duenos-procesos-section2-content-btn">Ver más</a>
                <a href="#" class="page-duenos-procesos-section2-content-btn page-duenos-procesos-section2-content-btn2"
                  *ngIf="userLogged.role_process">Agregar
                  dueños de procesos</a>
                <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2023/02/PPT-Mapa-de-procesos-v03-Junio.pdf"
                  target="_blank"
                  class="page-duenos-procesos-section2-content-btn page-duenos-procesos-section2-content-btn3">Mapa de
                  Procesos</a>
              </div>
            </div>
          </section>
          <section class="page-duenos-procesos-section3">
            <div class="container">
              <div class="page-duenos-procesos-section3-content">
                <div class="page-duenos-procesos-section3-content-box">
                  <div class="page-duenos-procesos-section3-content-box-left">
                    <img src="assets/img/icon-section3-duenos.png" alt=""
                      class="page-duenos-procesos-section3-content-box-left-img">
                  </div>
                  <div class="page-duenos-procesos-section3-content-box-right">
                    <h3 class="page-duenos-procesos-section3-content-box-right-title">{{infoProcesos.titulo_seccion3}}
                    </h3>
                    <div class="page-duenos-procesos-section3-content-box-right-text">
                      <p [innerHTML]="infoProcesos.texto_seccion3"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="page-duenos-procesos-section4">
            <div class="container">
              <div class="page-duenos-procesos-section4-content">
                <h3 class="page-duenos-procesos-section4-content-title">{{infoProcesos.titulo_seccion4}}</h3>
                <div class="page-duenos-procesos-section4-content-text">
                  <p [innerHTML]="infoProcesos.texto_seccion4"></p>
                </div>
              </div>
              <div class="page-duenos-procesos-section4-list">
                <ul class="page-duenos-procesos-section4-list-int">
                  <li class="page-duenos-procesos-section4-list-int-item"
                    *ngFor="let element of infoProcesos.elementos_seccion4">
                    <img src="{{element.icono}}" alt="" class="page-duenos-procesos-section4-list-int-item-img">
                    <div class="page-duenos-procesos-section4-list-int-item-content">
                      <p><b>{{element.titulo}}:</b> <span [innerHTML]="element.texto"></span></p>
                    </div>
                  </li>
                </ul>
                <div class="page-duenos-procesos-section4-list-img">
                  <img src="assets/img/icon-section4-duenos.png" alt="">
                </div>
              </div>
            </div>
          </section>
          <section class="page-duenos-procesos-section5">
            <div class="container">
              <div class="page-duenos-procesos-section5-content">
                <h3 class="page-duenos-procesos-section5-content-title" [innerHTML]="infoProcesos.titulo_seccion5"></h3>
                <div class="page-duenos-procesos-section5-content-text" [innerHTML]="infoProcesos.contenido_seccion5">
                </div>
              </div>
            </div>
          </section>
          <div class=" pt-5">
            <div class="tabs-search">
              <button data-tab="#news-duenos-de-procesos" class="item-tab-search active"
                *ngIf="newsDuenosProcesos?.length > 0">Noticias</button>
              <button data-tab="#galeries-duenos-de-procesos" class="item-tab-search "
                *ngIf="galeriaVideosDuenosProcesos?.length > 0">Galería</button>
              <button data-tab="#videos-duenos-de-procesos" class="item-tab-search "
                *ngIf="galeriasDuenosProcesos?.length > 0">Videos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="news-duenos-de-procesos" *ngIf="newsDuenosProcesos?.length > 0">
                <section class="section-custom5">
                  <div class="container" id="charlas">
                    <div class="swiper-container" [swiper]="carouselCharlas4.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let newDuenosProcesos of newsDuenosProcesos; let i = index">
                          <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ newDuenosProcesos.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="newDuenosProcesos.title"></h4>
                              <div class="card-text">
                                <p [innerHTML]="newDuenosProcesos.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + newDuenosProcesos.slug ]" class="card-link">Leer más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                        <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'duenos-de-procesos' }"
                          class="btn-custom-new">
                          <span>VER MÁS</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="galeries-duenos-de-procesos" style="display: none;"
                *ngIf="galeriaVideosDuenosProcesos?.length > 0">
                <section class="section-custom5">
                  <div class="container mb-5" id="charlas">
                    <div class="swiper-container" [swiper]="carouselCharlas5.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriaVideosDuenosProcesos; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700"
                            *ngIf="item.youtube == ''">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideoIframe($event, item.video_url)" *ngIf="item.youtube != ''"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/duenos-de-procesos' ]" [queryParams]="1"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="videos-duenos-de-procesos" style="display: none;"
                *ngIf="galeriasDuenosProcesos?.length > 0">
                <section class="section-custom5">
                  <div class="container" id="charlas">
                    <div class="swiper-container" [swiper]="carouselCharlas6.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriasDuenosProcesos; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="100">
                      <a [routerLink]="['/galeria-image-section/duenos-de-procesos' ]" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <div
          *ngIf="modalTitulo != 'Auditoría interna' && modalTitulo != 'Seguridad de la Información' && modalTitulo != 'Dueños de procesos'">
          <div class="modal-content-banner" *ngIf="modalTitulo != 'Gestión de Procesos'"
            [ngClass]="{'modal-content-banner-contain': modalTitulo === 'Seguridad de la Información' || modalTitulo === 'Gestión de Riesgo'}">
            <div class="swiper-container" [swiper]="carouselBanner.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let modalBanner of modalBanners; let i = index">
                  <img src="{{ modalBanner }}" alt="">
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="box-text-content-banner">
              <h3 class="title-modal1" [innerHTML]="modalTitulo"
                [ngClass]="modalTitulo == 'Gestión de Riesgo' ? 'title-bottom-title-header' : ''"></h3>
            </div>
          </div>

          <div class="modal-content-banner" *ngIf="modalTitulo == 'Gestión de Procesos'">
            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/11/BANNER-Proceso.jpg"
              style="object-position: center;" alt="">
          </div>

          <div [innerHTML]="newContentModal"></div>

          <!-- Gestión de Riesgo -->

          <div *ngIf="modalTitulo == 'Gestión de Riesgo'">
            <!-- Documentos -->
            <div *ngIf="modalDocuments?.length > 0" class="pt-5 pb-3">
              <div class="tabs-search">
                <button data-tab="#documentos-gestion-riesgo" class="item-tab-search active">Documentos</button>
              </div>
              <div class="tabs-content-search">
                <div class="item-tab-content-search" id="documentos-gestion-riesgo">
                  <div class="col-lg-8 mx-auto mb-5">
                    <div class="content-documents-custom-new3">
                      <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                        data-aos-duration="700" data-aos-delay="0"
                        *ngFor="let document of modalDocuments; let i = index">
                        <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                        <div class="box-acctions-custom-new3">
                          <span class="view-document">
                            <img src="assets/img/file.svg" alt="">
                          </span>
                          <span class="download-document">
                            <img src="assets/img/download.svg" alt="">
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-search">
              <button data-tab="#news-gestion-riesgo" class="item-tab-search active">Noticias</button>
              <button data-tab="#galeries-gestion-riesgo" class="item-tab-search ">Galería</button>
              <button data-tab="#videos-gestion-riesgo" class="item-tab-search ">Videos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="news-gestion-riesgo">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselCharlas2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let new1 of newsRiesgos; let i = index">
                          <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ new1.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ new1.title }}</h4>
                              <div class="card-text">
                                <p [innerHTML]="new1.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'riesgo' }" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="galeries-gestion-riesgo" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselCharlas2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriasRiesgos; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="100">
                      <a [routerLink]="['/galeria-image-section/riesgo' ]" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="videos-gestion-riesgo" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselCharlas2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriaVideosRiesgos; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700"
                            *ngIf="item.youtube == ''">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideoIframe($event, item.video_url)" *ngIf="item.youtube != ''"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/riesgo' ]" [queryParams]="1" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <!--Gestión de Procesos -->

          <div *ngIf="modalTitulo == 'Gestión de Procesos'">
            <!-- Documentos -->
            <div *ngIf="modalDocuments?.length > 0" class="pt-5 pb-3">
              <div class="tabs-search">
                <button data-tab="#documentos-gestion-procesos" class="item-tab-search active">Documentos</button>
              </div>
              <div class="tabs-content-search">
                <div class="item-tab-content-search" id="documentos-gestion-procesos">
                  <div class="col-lg-8 mx-auto mb-5">
                    <div class="content-documents-custom-new3">
                      <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                        data-aos-duration="700" data-aos-delay="0"
                        *ngFor="let document of modalDocuments; let i = index">
                        <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                        <div class="box-acctions-custom-new3">
                          <span class="view-document">
                            <img src="assets/img/file.svg" alt="">
                          </span>
                          <span class="download-document">
                            <img src="assets/img/download.svg" alt="">
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-search">
              <button data-tab="#news-gestion-procesos" class="item-tab-search active">Noticias</button>
              <button data-tab="#galeries-gestion-procesos" class="item-tab-search ">Galería</button>
              <button data-tab="#videos-gestion-procesos" class="item-tab-search ">Videos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="news-gestion-procesos">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselCharlas2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let new1 of newsProcesos; let i = index">
                          <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ new1.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ new1.title }}</h4>
                              <div class="card-text">
                                <p [innerHTML]="new1.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'gestion-de-procesos' }"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="galeries-gestion-procesos" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselCharlas2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriasProcesos; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="100">
                      <a [routerLink]="['/galeria-image-section/procesos' ]" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="videos-gestion-procesos" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselCharlas2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriaVideosProcesos; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700"
                            *ngIf="item.youtube == ''">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideoIframe($event, item.video_url)" *ngIf="item.youtube != ''"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/procesos' ]" [queryParams]="1" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div class="menu-mobile">
            <div class="content-menu-mobile">
              <a href="#menu-mobile1" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile2" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#" class="btn-design-float"><span></span></a>
              <a href="#menu-mobile3" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile4" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
            </div>
          </div>
          <div class="container box-footer">
            <div class="box-list-footer">
              <div class="list-footer">
                <h3 class="title-list-footer">Conoce Abastible</h3>
                <ul>
                  <li><a [routerLink]="['/conoce-abastible']"
                      fragment="presentacion-de-la-empresa-historia">Presentación de la
                      empresa/historia</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="mision-vision-valores">Misión -Visión -
                      Valores</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="directorio-y-administracion">Directorio
                      y Administración</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="productos-y-servicios">Productos y Servicios</a>
                  </li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="proceso-productivo">Proceso Productivo</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="abastible-tec">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Control Interno</h3>
                <ul>
                  <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1"
                      target="_blank">Canal de
                      Denuncias</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="auditoria-interna">Auditoría interna</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="seguridad-de-la-informacion">Seguridad de la
                      información</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-riesgo">Gestión de Riesgo</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-procesos">Gestión de Procesos</a>
                  </li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Seguridad</h3>
                <ul>
                  <li><a [routerLink]="['/seguridad']" fragment="integridad-operacional">Integridad Operacional</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="gestion-de-calidad">Gestión de Calidad</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="servicio-nacional-de-emergencias">Servicio Nacional de
                      Emergencias</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="manejo-manual-de-carga">Manejo Manual de Carga</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="medio-ambiente">Medio Ambiente</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Beneficios</h3>
                <ul>
                  <li><a [routerLink]="['/beneficios']" fragment="beneficios-para-colaboradores">Beneficios para
                      colaboradores</a></li>
                  <li><a [routerLink]="['/beneficios']" fragment="clima-organizacional">Clima organizacional</a></li>
                </ul>
              </div>
              <!--<div class="list-footer">
                    <h3 class="title-list-footer">Gobierno Corporativo</h3>
                    <ul>
                      <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de Denuncias</a></li>
                      <li><a [routerLink]="['/gobierno-corporativo']" fragment="section2">Compliance</a></li>
                  </ul>
                </div> -->
              <div class="list-footer">
                <h3 class="title-list-footer">Soluciones
                  Energéticas</h3>
                <ul>
                  <li><a [routerLink]="['/abastible-tec']" fragment="abastible-tec">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <!-- <div class="list-footer">
                <h3 class="title-list-footer">Innovación</h3>
                <ul>
                  <li><a [routerLink]="['/innovacion']" fragment="i-now">I-NOW</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="laboratorio-de-innovacion">Laboratorio de
                      Innovación</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="abastible-tec">AbastibleTec</a>
                  </li>
                </ul>
              </div> -->
              <div class="list-footer list-footer-rd">
                <h3 class="title-list-footer">Social</h3>
                <ul>
                  <li><a href="https://www.facebook.com/AbastibleCL" target="_blank"><img
                        src="assets/img/icons/icon-facebook.svg" alt="">AbastibleCL</a></li>
                  <li><a href="https://www.instagram.com/laenergiadevivir/" target="_blank"><img
                        src="assets/img/icons/icon-instagram.svg" alt="">/laenergiadevivir</a></li>
                  <li><a href="https://www.linkedin.com/company/abastible-s.a./" target="_blank"><img
                        src="assets/img/icons/icon-linkedin.svg" alt="">/company/abastible-s.a.</a></li>
                  <li><a href="https://www.youtube.com/user/AbastibleChileSA" target="_blank"><img
                        src="assets/img/icons/icon-youtube.svg" alt="">/AbastibleChileSA</a></li>
                  <li><a href="https://www.abastible.cl/" target="_blank"><img src="assets/img/icons/logo-abastible.svg"
                        alt="">abastible.cl</a></li>
                </ul>
              </div>
            </div>
            <a href="index.html" class="logo-footer"><img src="assets/img/logo-new.png" alt="" /></a>
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>

<div class="modal-img-full-img" id="show-full-img">
  <div class="overlay-modal"></div>
  <button class="close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<div class="modal-add-custom1">
  <div class="modal-add-custom1-overlay"></div>
  <div class="modal-add-custom1-content">
    <div class="modal-add-custom1-content-header">
      <h3 class="modal-add-custom1-content-header-title">Agregar dueños de procesos</h3>
    </div>
    <form [formGroup]="addForm" (ngSubmit)="addUserProcess()" class="modal-add-custom1-content-body">
      <div class="modal-add-custom1-content-body-box-input">
        <label for="members_abastible">Nombre</label>
        <input type="text" name="members_abastible" id="members_abastible" formControlName="user_add">
      </div>
      <div class="modal-add-custom1-content-body-box-input">
        <label for="cargo_user">Cargo</label>
        <textarea name="cargo_user" id="cargo_user" formControlName="cargo_user" cols="30" rows="3"></textarea>
      </div>
      <div class="modal-add-custom1-content-body-box-input">
        <label for="photo_user">Foto</label>
        <input type="file" (change)="this.onFilechange($event)" formControlName="photo_user" name="photo_user"
          id="photo_user">
      </div>
      <button class="modal-add-custom1-content-body-btn" type="submit">Agregar</button>
    </form>
  </div>
</div>