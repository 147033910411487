<section class="navbar-custom2">
  <div class="container">
    <div id="carousel-navbar">
      <div (click)="scroll(section.slug)" class="item-navbar-custom2 item-directorio-administracion"
        *ngFor="let section of sections; let i = index">
        <h6 class="title-navbar-custom">{{ section.titulo }}</h6>
      </div>
    </div>
  </div>
</section>
<div class="bg-white bg-white-custom">
  <section class="section-banner-custom-new" id="section0"
    style="background-image: url(assets/img/banners/banner_5.jpg);">
    <div class="overlay-banner-custom-new"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">
            Seguridad
          </h2>
          <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
            data-aos-delay="400"></span>
          <!-- <div class="box-text-banner-custon-new" data-aos="fade-right" data-aos-duration="700">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
          </div> -->
        </div>
      </div>
    </div>
  </section>
  <div id="animationWindow"></div>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs breadcrumbs-sections">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>Seguridad</span>
        </div>
      </div>
    </div>
  </section>
  <div *ngFor="let section of sections; let i = index" class="sections-custom-new">
    <section class="section-custom-new" id="{{section.slug}}">
      <div class="container">
        <div class="row">
          <div class="" [ngClass]="{
              'col-lg-3': section.video,
              'col-lg-4': !section.video
            }">
            <div class="box-title-custom-new">
              <h3 class="title-custom-new" data-aos="fade-up" data-aos-duration="700">
                {{ section.titulo }}
              </h3>
              <span class="line-title-custom-new" data-aos="fade-right" data-aos-duration="700"
                data-aos-delay="400"></span>
            </div>
          </div>
          <div class="" [ngClass]="{
              'col-lg-6': section.video,
              'col-lg-8': !section.video
            }">
            <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
              <!-- <div class="text-destaqued-custom-new">
                <h5>{{ section.subtitulo }}</h5>
              </div> -->
              <div class="text-custom-new">
                <p [innerHTML]="textMin(section.descripcion_corta)"></p>
              </div>
              <a [routerLink]="[section.enlace]" class="btn-custom-new" *ngIf="section.enlace">
                <span>VER MÁS</span>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                  <g>
                    <g>
                      <path
                        d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                    </g>
                  </g>
                </svg>
              </a>
              <span (click)="
                  fullTextModal(
                    section.titulo,
                    section.texto,
                    section.banners,
                    section.slug,section.documentos
                  )
                " class="btn-custom-new" *ngIf="!section.enlace">
                <span>VER MÁS</span>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                  <g>
                    <g>
                      <path
                        d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-img-section-custom-new" *ngIf="section.video">
        <div class="diagonal-cut"></div>
        <img src="{{ section.video }}" [ngClass]="{
            'custom-position2':
              section.titulo == 'Gestión de Calidad' ||
              section.titulo == 'Servicio Nacional de Emergencias',
            '': !section.video
          }" alt="" />
        <!-- <video autoplay muted loop [muted]="true">
          <source src="{{ section.video }}">
        </video> -->
      </div>
    </section>

    <section class="section-image-divider" *ngIf="section.imagen_separacion" [ngStyle]="{
        'background-image': 'url(' + section.imagen_separacion + ')'
      }"></section>
  </div>
  <div>
    <div class="tabs-search">
      <button data-tab="#news-general" class="item-tab-search active">Noticias</button>
      <button data-tab="#galeries-general" class="item-tab-search" *ngIf="galeria1">Galería</button>
      <button data-tab="#videos-general" class="item-tab-search">Videos</button>
    </div>
    <div class="tabs-content-search">
      <div class="item-tab-content-search" id="news-general">
        <section class="section-custom5">
          <div class="container">
            <!-- <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">
              NOTICIAS
            </h3>
            <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span> -->
            <div #carouselNoticias class="swiper-container" [swiper]="carouselGalerias.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let new1 of news; let i = index">
                  <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ new1.img }}" alt="" />
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="new1.title"></h4>
                      <div class="card-text">
                        <p [innerHTML]="new1.text"></p>
                      </div>
                      <a [routerLink]="['/noticia/' + new1.slug]" class="card-link">Leer más</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                <a [routerLink]="['/noticias']" [queryParams]="{ categoryslug: 'noticias-de-seguridad' }"
                  class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="galeries-general" *ngIf="galeria1" style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselGalerias.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeria1; let i = index">
                  <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                    class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                    data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.feature_img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-image-section/seguridad' ]" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="videos-general" style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <!-- <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">
              Videos
            </h3>
            <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span> -->
            <div #carouselVideos class="swiper-container" [swiper]="carouselGalerias.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galerias; let i = index">
                  <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                    (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.img }}" alt="" />
                      <div class="diagonal-cut"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                        class="btn-play-desing1" />
                    </div>
                    <div class="card-content">
                      <h4 class="card-title">{{ item.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-videos-section/seguridad']" [queryParams]="1" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <section class="section-custom-new3" *ngIf="documentos">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">DOCUMENTOS</h3>
          <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
          <div class="content-documents-custom-new3">
            <a href="{{ documento.link }}" class="item-document-custom-new3"
              *ngFor="let documento of documentos; let i = index" target="_blank" data-aos="fade-up"
              data-aos-duration="700" data-aos-delay="0">
              <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>


</div>
<!-- <a href="mailto:inow@abastible.cl" class="btn-mailto-inow"><img
    src="https://intranet.abastible.cl/wp-content/themes/intranet-abastible/assets/img/inow/idea.png"
    alt=""><span>¿Tienes una idea?</span></a> -->
<div class="modal-img-full modal-single-content" [ngClass]="modalTextShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullTextModal()"></div>
  <div class="box-modal-text-full">
    <div class="modal-header-single-content">
      <button class="btn-back-single-content" (click)="closeFullTextModal()">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512;" xml:space="preserve"
          fill="#ff7000">
          <g>
            <g>
              <path
                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
            </g>
          </g>
        </svg>
      </button>
      <h3 class="title-header-modal">
        Seguridad / <span [innerHTML]="modalTitulo"></span>
      </h3>
      <!-- <button class="close" (click)="closeFullTextModal()">
        <img src="assets/img/btn-close.svg" alt="">
      </button> -->
    </div>
    <div class="modal-text">
      <div class="modal-content-text">
        <div [innerHTML]="newContentModal"></div>

        <!-- Manejo Manual de Carga -->

        <div *ngIf="modalTitulo == 'Manejo Manual de Carga'">
          <!-- Documentos -->
          <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
            <div class="tabs-search">
              <button data-tab="#documentos-gestion-calidad" class="item-tab-search active">Documentos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="documentos-gestion-calidad">
                <div class="col-lg-8 mx-auto mb-5">
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-search">
            <button data-tab="#news-manejo-manual-de-carga" class="item-tab-search active"
              *ngIf="news5.length > 0">Noticias</button>
            <button data-tab="#galeries-manejo-manual-de-carga" class="item-tab-search "
              *ngIf="galeriasManejoManualDeCarga">Galería</button>
            <button data-tab="#videos-manejo-manual-de-carga" class="item-tab-search "
              *ngIf="galeriaManejoManualDeCarga.length > 0">Videos</button>
          </div>
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-manejo-manual-de-carga" *ngIf="news5.length > 0">
              <section class="section-custom5">
                <div class="container" id="charlas">
                  <div class="swiper-container" #carouselNoticias3 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let new2 of news5; let i = index">
                        <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ new2.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="new2.title"></h4>
                            <div class="card-text">
                              <p [innerHTML]="new2.text"></p>
                            </div>
                            <a [routerLink]="['/noticia/' + new2.slug]" class="card-link">Leer más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/noticias']" [queryParams]="{ categoryslug: 'manejo-manual-de-carga' }"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="galeries-manejo-manual-de-carga" style="display: none;"
              *ngIf="galeriasManejoManualDeCarga">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselGaleriasOne [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriasManejoManualDeCarga;let i = index">
                        <a [routerLink]="['/galeria/' + item.ID]" [queryParams]="{ title: item.post_title }"
                          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                          data-aos="fade-up" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.feature_img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news mt-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-image-section/manejo-manual-de-carga']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="videos-manejo-manual-de-carga" style="display: none;"
              *ngIf="galeriaManejoManualDeCarga.length > 0">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselVideos2 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriaManejoManualDeCarga; let i = index">
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1" />
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-videos-section/manejo-manual-de-carga']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <!-- Gestión de Calidad -->

        <div *ngIf="modalTitulo == 'Gestión de Calidad'">
          <!-- Documentos -->
          <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
            <div class="tabs-search">
              <button data-tab="#documentos-gestion-calidad" class="item-tab-search active">Documentos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="documentos-gestion-calidad">
                <div class="col-lg-8 mx-auto mb-5">
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-search">
            <button data-tab="#news-gestion-de-calidad" class="item-tab-search active"
              *ngIf="news4.length > 0">Noticias</button>
            <button data-tab="#galeries-gestion-de-calidad" class="item-tab-search "
              *ngIf="galeriasGestionCalidad">Galería</button>
            <button data-tab="#videos-gestion-de-calidad" class="item-tab-search "
              *ngIf="galeriaGestionCalidad.length > 0">Videos</button>
          </div>
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-gestion-de-calidad" *ngIf="news4.length > 0">
              <section class="section-custom5">
                <div class="container" id="charlas">
                  <div class="swiper-container" #carouselNoticias3 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let new2 of news4; let i = index">
                        <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ new2.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="new2.title"></h4>
                            <div class="card-text">
                              <p [innerHTML]="new2.text"></p>
                            </div>
                            <a [routerLink]="['/noticia/' + new2.slug]" class="card-link">Leer más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/noticias']" [queryParams]="{ categoryslug: 'gestion-de-calidad' }"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="galeries-gestion-de-calidad" style="display: none;"
              *ngIf="galeriasGestionCalidad">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselGaleriasOne [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriasGestionCalidad;let i = index">
                        <a [routerLink]="['/galeria/' + item.ID]" [queryParams]="{ title: item.post_title }"
                          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                          data-aos="fade-up" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.feature_img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news mt-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-image-section/gestion-de-calidad']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="videos-gestion-de-calidad" style="display: none;"
              *ngIf="galeriaGestionCalidad.length > 0">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselVideos2 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriaGestionCalidad; let i = index">
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1" />
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-videos-section/gestion-de-calidad']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <!-- Servicio Nacional de Emergencias -->

        <div *ngIf="modalTitulo == 'Servicio Nacional de Emergencias'">
          <!-- Documentos -->
          <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
            <div class="tabs-search">
              <button data-tab="#documentos-servicio-nacional-emergencias"
                class="item-tab-search active">Documentos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="documentos-servicio-nacional-emergencias">
                <div class="col-lg-8 mx-auto mb-5">
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-search">
            <button data-tab="#news-servicio-nacional-de-emergencias" class="item-tab-search active">Noticias</button>
            <button data-tab="#galeries-servicio-nacional-de-emergencias" class="item-tab-search "
              *ngIf="galeriasServicioNacionalDeEmergencias">Galería</button>
            <button data-tab="#videos-servicio-nacional-de-emergencias" class="item-tab-search "
              *ngIf="galeriaServicioNacionalDeEmergencias.length > 0">Videos</button>
          </div>
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-servicio-nacional-de-emergencias">
              <section class="section-custom5">
                <div class="container" id="charlas">
                  <div class="swiper-container" #carouselNoticias3 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let new2 of news3; let i = index">
                        <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ new2.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="new2.title"></h4>
                            <div class="card-text">
                              <p [innerHTML]="new2.text"></p>
                            </div>
                            <a [routerLink]="['/noticia/' + new2.slug]" class="card-link">Leer más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/noticias']" [queryParams]="{ categoryslug: 'servicio-nacional-de-emergencias' }"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="galeries-servicio-nacional-de-emergencias" style="display: none;"
              *ngIf="galeriasServicioNacionalDeEmergencias">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselGaleriasOne [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide"
                        *ngFor="let item of galeriasServicioNacionalDeEmergencias;let i = index">
                        <a [routerLink]="['/galeria/' + item.ID]" [queryParams]="{ title: item.post_title }"
                          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                          data-aos="fade-up" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.feature_img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news mt-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-image-section/servicio-nacional-de-emergencias']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="videos-servicio-nacional-de-emergencias" style="display: none;"
              *ngIf="galeriaServicioNacionalDeEmergencias.length > 0">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselVideos2 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide"
                        *ngFor="let item of galeriaServicioNacionalDeEmergencias; let i = index">
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1" />
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-videos-section/servicio-nacional-de-emergencias']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <!-- Medio Ambiente -->

        <div *ngIf="modalTitulo == 'Medio Ambiente'">
          <!-- Documentos -->
          <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
            <div class="tabs-search">
              <button data-tab="#documentos-medio-ambiente" class="item-tab-search active">Documentos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="documentos-medio-ambiente">
                <div class="col-lg-8 mx-auto mb-5">
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-search">
            <button data-tab="#news-medio-ambiente" class="item-tab-search active">Noticias</button>
            <button data-tab="#galeries-medio-ambiente" class="item-tab-search "
              *ngIf="galeriasMedioAmbiente">Galería</button>
            <button data-tab="#videos-medio-ambiente" class="item-tab-search "
              *ngIf="galeriaMedioAmbiente.length > 0">Videos</button>
          </div>
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-medio-ambiente">
              <section class="section-custom5">
                <div class="container" id="charlas">
                  <div class="swiper-container" #carouselNoticias2 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let new1 of news1; let i = index">
                        <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ new1.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="new1.title"></h4>
                            <div class="card-text">
                              <p [innerHTML]="new1.text"></p>
                            </div>
                            <a [routerLink]="['/noticia/' + new1.slug]" class="card-link">Leer más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/noticias']" [queryParams]="{ categoryslug: 'medio-ambiente' }"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="galeries-medio-ambiente" style="display: none;"
              *ngIf="galeriasMedioAmbiente">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselGaleriasOne [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriasMedioAmbiente;let i = index">
                        <a [routerLink]="['/galeria/' + item.ID]" [queryParams]="{ title: item.post_title }"
                          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                          data-aos="fade-up" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.feature_img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news mt-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-image-section/medio-ambiente']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="videos-medio-ambiente" style="display: none;"
              *ngIf="galeriaMedioAmbiente.length > 0">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselVideos2 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriaMedioAmbiente; let i = index">
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1" />
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-videos-section/medio-ambiente']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <!-- Integridad Operacional -->

        <div *ngIf="modalTitulo == 'Integridad Operacional'">
          <!-- Documentos -->
          <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
            <div class="tabs-search">
              <button data-tab="#documentos-integridad-operacional" class="item-tab-search active">Documentos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="documentos-integridad-operacional">
                <div class="col-lg-8 mx-auto mb-5">
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-search">
            <button data-tab="#news-integridad-operacional" class="item-tab-search active">Noticias</button>
            <button data-tab="#galeries-integridad-operacional" class="item-tab-search ">Galería</button>
            <button data-tab="#videos-integridad-operacional" class="item-tab-search ">Videos</button>
          </div>
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-integridad-operacional">
              <section class="section-custom5">
                <div class="container" id="charlas">
                  <div class="swiper-container" #carouselNoticias3 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let new2 of news2; let i = index">
                        <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ new2.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="new2.title"></h4>
                            <div class="card-text">
                              <p [innerHTML]="new2.text"></p>
                            </div>
                            <a [routerLink]="['/noticia/' + new2.slug]" class="card-link">Leer más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/noticias']" [queryParams]="{ categoryslug: 'integridad-operacional' }"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="galeries-integridad-operacional" style="display: none;">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselGaleriasOne [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriasIntegridadOperacional;let i = index">
                        <a [routerLink]="['/galeria/' + item.ID]" [queryParams]="{ title: item.post_title }"
                          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                          data-aos="fade-up" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.feature_img }}" alt="" />
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news mt-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-image-section/integridad-operacional']" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="videos-integridad-operacional" style="display: none;">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" #carouselVideos2 [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriaIntegridadOpeacional; let i = index">
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="" />
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1" />
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-videos-section/16']" [queryParams]="1" class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <footer>
          <div class="menu-mobile">
            <div class="content-menu-mobile">
              <a href="#menu-mobile1" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile2" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#" class="btn-design-float"><span></span></a>
              <a href="#menu-mobile3" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile4" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
            </div>
          </div>
          <div class="container box-footer">
            <div class="box-list-footer">
              <div class="list-footer">
                <h3 class="title-list-footer">Conoce Abastible</h3>
                <ul>
                  <li><a [routerLink]="['/conoce-abastible']"
                      fragment="presentacion-de-la-empresa-historia">Presentación de la
                      empresa/historia</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="mision-vision-valores">Misión -Visión -
                      Valores</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="directorio-y-administracion">Directorio
                      y Administración</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="productos-y-servicios">Productos y Servicios</a>
                  </li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="proceso-productivo">Proceso Productivo</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="abastible-tec">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Control Interno</h3>
                <ul>
                  <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1"
                      target="_blank">Canal de
                      Denuncias</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="auditoria-interna">Auditoría interna</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="seguridad-de-la-informacion">Seguridad de la
                      información</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-riesgo">Gestión de Riesgo</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-procesos">Gestión de Procesos</a>
                  </li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Seguridad</h3>
                <ul>
                  <li><a [routerLink]="['/seguridad']" fragment="integridad-operacional">Integridad Operacional</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="gestion-de-calidad">Gestión de Calidad</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="servicio-nacional-de-emergencias">Servicio Nacional de
                      Emergencias</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="manejo-manual-de-carga">Manejo Manual de Carga</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="medio-ambiente">Medio Ambiente</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Beneficios</h3>
                <ul>
                  <li><a [routerLink]="['/beneficios']" fragment="beneficios-para-colaboradores">Beneficios para
                      colaboradores</a></li>
                  <li><a [routerLink]="['/beneficios']" fragment="clima-organizacional">Clima organizacional</a></li>
                </ul>
              </div>
              <!--<div class="list-footer">
                    <h3 class="title-list-footer">Gobierno Corporativo</h3>
                    <ul>
                      <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de Denuncias</a></li>
                      <li><a [routerLink]="['/gobierno-corporativo']" fragment="section2">Compliance</a></li>
                  </ul>
                </div> -->
              <div class="list-footer">
                <h3 class="title-list-footer">Soluciones
                  Energéticas</h3>
                <ul>
                  <li><a [routerLink]="['/abastible-tec']" fragment="abastible-tec">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <!-- <div class="list-footer">
                <h3 class="title-list-footer">Innovación</h3>
                <ul>
                  <li><a [routerLink]="['/innovacion']" fragment="i-now">I-NOW</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="laboratorio-de-innovacion">Laboratorio de
                      Innovación</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="abastible-tec">AbastibleTec</a>
                  </li>
                </ul>
              </div> -->
              <div class="list-footer list-footer-rd">
                <h3 class="title-list-footer">Social</h3>
                <ul>
                  <li><a href="https://www.facebook.com/AbastibleCL" target="_blank"><img
                        src="assets/img/icons/icon-facebook.svg" alt="">AbastibleCL</a></li>
                  <li><a href="https://www.instagram.com/laenergiadevivir/" target="_blank"><img
                        src="assets/img/icons/icon-instagram.svg" alt="">/laenergiadevivir</a></li>
                  <li><a href="https://www.linkedin.com/company/abastible-s.a./" target="_blank"><img
                        src="assets/img/icons/icon-linkedin.svg" alt="">/company/abastible-s.a.</a></li>
                  <li><a href="https://www.youtube.com/user/AbastibleChileSA" target="_blank"><img
                        src="assets/img/icons/icon-youtube.svg" alt="">/AbastibleChileSA</a></li>
                  <li><a href="https://www.abastible.cl/" target="_blank"><img src="assets/img/icons/logo-abastible.svg"
                        alt="">abastible.cl</a></li>
                </ul>
              </div>
            </div>
            <a href="index.html" class="logo-footer"><img src="assets/img/logo-new.png" alt="" /></a>
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>