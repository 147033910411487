import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Pages
import {
  HomeComponent,
  HomeNewComponent,
  LoginComponent,
  SinglePostComponent,
  SingleEventosComponent,
  SinglePageComponent,
  SingleContentComponent,
  GaleriasPageComponent,
  DocumentosPageComponent,
  ContactanosPageComponent,
  SingleDocumentosPageComponent,
  GaleriaVideosPageComponent,
  ConozcaAbastibleComponent,
  HacerLasCosasBienComponent,
  BeneficiosAbastibleComponent,
  BeneficiosNewAbastibleComponent,
  NoticiasAbastibleComponent,
  PuntoPrensaAbastibleComponent,
  ConcursosInternosAbastibleComponent,
  LinksAbastibleComponent,
  IndicadoresAbastibleComponent,
  CanalDenunciasAbastibleComponent,
  ManualMarcaComponent,
  Office365Component,
  MasAbastibleComponent,
  ComunicadosAbastibleComponent,
  DesarrolloOrganizacionalComponent,
  GobiernoCorporativoComponent,
  GobiernoCorporativoTestComponent,
  InnovacionComponent,
  IdeasDesafios1Component,
  IdeasDesafios2Component,
  IdeasDesafios3Component,
  IdeasDesafios4Component,
  IdeasDesafios5Component,
  ConozcaAbastible2Component,
  PerfilPageComponent,
  CumpleanosPageComponent,
  ListaGruposComponent,
  SingleGrupoComponent,
  SingleGaleriaComponent,
  EmbajadoresComponent,
  EventosComponent,
  SeguridadComponent,
  GaleriaVideosSectionPageComponent,
  GaleriaImageSectionPageComponent,
  DesafiosAbastibleComponent,
  ComunidadBuenasPracticasComponent,
  EmbajadoresInowComponent,
  DocumentosCBPComponent,
  GaleriaVideosSectionInowPageComponent,
  InstructivosCoronavirusComponent,
  NoticiasGruposComponent,
  EmbajadoresServicioComponent,
  PotencialAbastibleComponent,
  ComiteParitarioComponent,
  SearchGruposComponent,
  GaleriaGruposPageComponent,
  FormularioDesafio6Component,
  FormularioDesafio7InowComponent,
  IdeasDesafios6Component,
  IdeasDesafios7Component,
  SostenibilidadComponent,
  JuegoRiesgoComponent,
  TechoComponent,
  PreguntasFrecuentesComponent,
  EstandarSenaliticaomponent,
  RevistaComponent,
  ConcursoAbastibleComponent,
  VideoInduccionPageComponent,
  ComiteParitarioDeHigieneYSeguridadOficinaCentral,
  PropositoAbastibleComponent,
  RespositorioLegalComponent,
  RedirectHomeComponent,
  FormulariosModuloMMComponent,
  DuenosDeProcesosComponent,
  RedesSocialesComponent,
  MetodologiaGoComponent,
  EmprendimientosComponent,
  TriviaCiberseguridad1AbastibleComponent,
  TriviaCiberseguridad2AbastibleComponent,
  TriviaCiberseguridad3AbastibleComponent,
  AgilidadAbastibleComponent,
  ReciclarEsColaborarAbastibleComponent,
  LeyKarinAbastibleComponent,
  NoticiasConexionNaranjaAbastibleComponent,
  NoticiasConexionNaranjaEdicionAbastibleComponent,
  NoticiasConexionNaranjaSingleAbastibleComponent,
  RepositorioImagenesAbastibleComponent,
  RepositorioImagenesCategoriaAbastibleComponent,
  TriviaEnemigoSecretoAbastibleComponent,
  ModeloHibridoAbastibleComponent
} from "@pages";

// Layouts
import { BaseLayout, InternLayout } from "@layouts";

const routes: Routes = [
  {
    path: "",
    component: BaseLayout,
    children: [
      {
        path: "",
        component: LoginComponent,
      },
    ],
  },
  {
    path: "",
    component: InternLayout,
    children: [
      {
        path: "muro",
        component: HomeNewComponent,
      },
      {
        path: "muro-new",
        component: HomeComponent,
      },
      {
        path: "noticia/:slug",
        component: SinglePostComponent,
      },
      {
        path: "evento/:id",
        component: SingleEventosComponent,
      },
      {
        path: "contenido/:slug",
        component: SingleContentComponent,
      },
      {
        path: "galerias/:slug",
        component: GaleriasPageComponent,
      },
      {
        path: "galeria-grupos/:slug",
        component: GaleriaGruposPageComponent,
      },
      {
        path: "galeria-videos/:id",
        component: GaleriaVideosPageComponent,
      },
      {
        path: "perfil",
        component: PerfilPageComponent,
      },
      {
        path: "documentos",
        component: DocumentosPageComponent,
      },
      {
        path: "contactanos",
        component: ContactanosPageComponent,
      },
      {
        path: "concurso-verano",
        component: ConcursoAbastibleComponent,
      },
      {
        path: "video-induccion",
        component: VideoInduccionPageComponent,
      },
      {
        path: "conoce-abastible",
        component: ConozcaAbastibleComponent,
      },
      {
        path: "control-interno",
        component: HacerLasCosasBienComponent,
      },
      {
        path: "preguntas-frecuentes",
        component: PreguntasFrecuentesComponent,
      },
      {
        path: "beneficios",
        component: BeneficiosNewAbastibleComponent,
      },
      {
        path: "beneficios-new",
        component: BeneficiosNewAbastibleComponent,
      },
      {
        path: "desarrollo-organizacional",
        component: DesarrolloOrganizacionalComponent,
      },
      {
        path: "gobierno-corporativo",
        component: GobiernoCorporativoComponent,
      },
      {
        path: "gobierno-corporativo-test",
        component: GobiernoCorporativoTestComponent,
      },
      {
        path: "abastible-tec",
        component: InnovacionComponent,
      },
      {
        path: "ideas-desafio1",
        component: IdeasDesafios1Component,
      },
      {
        path: "ideas-desafio2",
        component: IdeasDesafios2Component,
      },
      {
        path: "ideas-desafio3",
        component: IdeasDesafios3Component,
      },
      {
        path: "ideas-desafio4",
        component: IdeasDesafios4Component,
      },
      {
        path: "ideas-desafio5",
        component: IdeasDesafios5Component,
      },
      {
        path: "ideas-desafio6",
        component: IdeasDesafios6Component,
      },
      {
        path: "ideas-desafio7",
        component: IdeasDesafios7Component,
      },
      {
        path: "formulario-desafio6",
        component: FormularioDesafio6Component,
      },
      {
        path: "formulario-desafio7inow",
        component: FormularioDesafio7InowComponent,
      },
      {
        path: "noticias",
        component: NoticiasAbastibleComponent,
      },
      {
        path: "punto-de-prensa",
        component: PuntoPrensaAbastibleComponent,
      },
      {
        path: "concursos-internos",
        component: ConcursosInternosAbastibleComponent,
      },
      {
        path: "links",
        component: LinksAbastibleComponent,
      },
      {
        path: "indicadores",
        component: IndicadoresAbastibleComponent,
      },
      {
        path: "canal-de-denuncias",
        component: CanalDenunciasAbastibleComponent,
      },
      {
        path: "cumpleanos",
        component: CumpleanosPageComponent,
      },
      {
        path: "manual-de-marca",
        component: ManualMarcaComponent,
      },
      {
        path: "office-365",
        component: Office365Component,
      },
      {
        path: "mas-abastible",
        component: MasAbastibleComponent,
      },
      {
        path: "comunicados",
        component: ComunicadosAbastibleComponent,
      },
      {
        path: "grupos",
        component: ListaGruposComponent,
      },
      {
        path: "embajadores",
        component: EmbajadoresComponent,
      },
      {
        path: "eventos",
        component: EventosComponent,
      },
      {
        path: "seguridad",
        component: SeguridadComponent,
      },
      {
        path: "galeria-videos-section/:id",
        component: GaleriaVideosSectionPageComponent,
      },
      {
        path: "galeria-videos-section-inow/:id",
        component: GaleriaVideosSectionInowPageComponent,
      },
      {
        path: "galeria-image-section/:slug",
        component: GaleriaImageSectionPageComponent,
      },
      {
        path: "documentos-grupos/:slug",
        component: DocumentosCBPComponent,
      },
      {
        path: "instructivos-coronavirus",
        component: InstructivosCoronavirusComponent,
      },
      {
        path: "conozca-abastible2",
        component: ConozcaAbastible2Component,
      },
      {
        path: "desafios",
        component: DesafiosAbastibleComponent,
      },
      {
        path: "sostenibilidad",
        component: SostenibilidadComponent,
      },
      {
        path: "juego-riesgo",
        component: JuegoRiesgoComponent,
      },
      {
        path: "techo",
        component: TechoComponent,
      },
      {
        path: "single-documentos/:letter",
        component: SingleDocumentosPageComponent,
      },
      {
        path: "group/comunidad-buenas-practicas",
        component: ComunidadBuenasPracticasComponent,
      },
      {
        path: "group/embajadores-inow",
        component: EmbajadoresInowComponent,
      },
      {
        path: "group/embajadores-de-servicio",
        component: EmbajadoresServicioComponent,
      },
      {
        path: "group/potencial-abastible",
        component: PotencialAbastibleComponent,
      },
      {
        path: "group/comite-paritario",
        component: ComiteParitarioComponent,
      },
      {
        path: "busqueda/:busqueda",
        component: SearchGruposComponent,
      },
      {
        path: "estandar-senalitica",
        component: EstandarSenaliticaomponent,
      },
      {
        path: "book-potencial",
        component: RevistaComponent,
      },
      {
        path: "comite-paritario-de-higiene-y-seguiridad-oficina-central",
        component: ComiteParitarioDeHigieneYSeguridadOficinaCentral,
      },
      {
        path: "proposito",
        component: PropositoAbastibleComponent,
      },
      {
        path: "repositorio-legal",
        component: RespositorioLegalComponent,
      },
      {
        path: "procurement",
        component: FormulariosModuloMMComponent,
      },
      {
        path: "duenos-de-procesos",
        component: DuenosDeProcesosComponent,
      },
      {
        path: "redes-sociales",
        component: RedesSocialesComponent,
      },
      {
        path: "metodologia-go",
        component: MetodologiaGoComponent,
      },
      {
        path: "emprendimientos",
        component: EmprendimientosComponent,
      },
      {
        path: "trivia-enemigo-secreto",
        component: TriviaEnemigoSecretoAbastibleComponent,
      },
      {
        path: "trivia-ciberseguridad1-disabled-bk",
        component: TriviaCiberseguridad1AbastibleComponent,
      },
      {
        path: "trivia-ciberseguridad2-disabled-bk",
        component: TriviaCiberseguridad2AbastibleComponent,
      },
      {
        path: "trivia-ciberseguridad3-disabled-bk",
        component: TriviaCiberseguridad3AbastibleComponent,
      },
      {
        path: "agilidad",
        component: AgilidadAbastibleComponent,
      },
      {
        path: "reciclar-es-colaborar",
        component: ReciclarEsColaborarAbastibleComponent,
      },
      {
        path: "ley-karin",
        component: LeyKarinAbastibleComponent,
      },
      {
        path: "modelo-hibrido",
        component: ModeloHibridoAbastibleComponent,
      },
      {
        path: "noticias-conexion-naranja",
        component: NoticiasConexionNaranjaAbastibleComponent,
      },
      {
        path: "repositorio-de-imagenes",
        component: RepositorioImagenesAbastibleComponent,
      },
      {
        path: "repositorio-de-imagenes/:categoria",
        component: RepositorioImagenesCategoriaAbastibleComponent,
      },
      {
        path: "noticias-conexion-naranja/:edicion",
        component: NoticiasConexionNaranjaEdicionAbastibleComponent,
      },
      {
        path: "noticias-conexion-naranja/:edicion/:slug",
        component: NoticiasConexionNaranjaSingleAbastibleComponent,
      },
      {
        path: "grupo/:slug",
        component: SingleGrupoComponent,
      },
      {
        path: "noticias-grupo/:post-type",
        component: NoticiasGruposComponent,
      },
      {
        path: "galeria/:id",
        component: SingleGaleriaComponent,
      },
      //Wild Card Route for 404 request
      {
        path: "**",
        pathMatch: "full",
        redirectTo: "/",
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
