<header>
  <div class="container content-header content-header-new">
    <a href="https://intranet.abastible.cl/muro" class="logo-header">
      <img src="assets/img/logo-header-new2.png" alt="" />
    </a>
    <div class="box-search-header">
      <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm.value.search)">
        <div class="box-search-header-int">
          <input type="text" name="search" class="input-search" placeholder="Buscar en toda la intranet" ngModel="">
        </div>
      </form>
    </div>
    <div class="box-links-heade">
      <a [routerLink]="['/contactanos']" routerLinkActive="active" class="link-header">Contáctanos</a>
      <a href="https://intranet.abastible.cl/wp/login/?hash={{setHash}}" *ngIf="!loggedIn" class="btn-login">Login</a>
      <button class="btn-mobile">
        <!-- <span></span><span></span> -->
        <a [routerLink]="['/perfil']" *ngIf="loggedIn" class="icon-notifications">
          <img src="assets/img/logo-notificacion.png" alt="Notificaciones" height="24" />
          <!-- <img src="assets/img/packard-bell.png" alt="Notificaciones" height="24" /> -->
          <span class="badge">{{totalNotification}}</span>
        </a>
      </button>
      <a (click)="logout()" *ngIf="loggedIn" class="btn-login">Logout</a>
    </div>
  </div>
</header>
<!-- <button (click)="showToaster()">
  Show Toaster
</button> -->